<template>
  <div>
    <Loader v-model="loading" />
    <PrinterDialog v-model="printer" :impresoras="impresoras" />
    
    <v-app-bar app>
      <v-app-bar app color="primary" dark>
        <v-icon @click="goBack">mdi-keyboard-backspace</v-icon>
        <v-spacer></v-spacer>
        <v-toolbar-title>Administrador de Tickets</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon>mdi-account-circle</v-icon> {{ session.usuario.toUpperCase() }}
      </v-app-bar>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-card>
                                                <v-card-title> Informacion </v-card-title>
                                                <v-card-text>
                                                  <v-container fluid>
                                                    <v-row>
                                                      <v-col>
                                                        <v-select
                                                          v-model="version"
                                                          :items="versiones"
                                                          label="Tipo"
                                                          item-text="name"
                                                          item-value="value"
                        outlined
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        type="date"
                        label="Fecha Inicio"
                        outlined
                        v-model="info.fecha_inicio"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        type="date"
                        label="Fecha Final"
                        outlined
                        v-model="info.fecha_fin"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-combobox
                        placeholder="Todos"
                        :items="usuarios"
                        item-text="strUsuarioPOS"
                        item-value="strUsuarioPOS"
                        v-model="info.usuario"
                        label="Usuario"
                        outlined
                        :return-object="false"
                        dense
                      >
                      </v-combobox>
                    </v-col>
                    <v-col md="1">
                      <v-btn x-large block color="primary" @click="buscar()">
                        Buscar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>Resultados</v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row v-show="visibleVenta">
                    <v-col>
                      <v-data-table :headers="headers" :items="results" >
                        <template v-slot:[`item.intEstatus`]="{ item }">
                          {{ getEstatus(item.intEstatus) }}
                        </template>
                        <template v-slot:[`item.importe`]="{ item }">
                          {{ isMoney(item.importe) }}
                        </template>
                        <template v-slot:[`item.dblSubtotal`]="{ item }">
                          {{ isMoney(item.dblSubtotal) }}
                        </template>
                        <template v-slot:[`item.dblTotal`]="{ item }">
                          {{ isMoney(item.dblTotal) }}
                        </template>
                        <template v-slot:[`item.strUsuarioPOS`]="{ item }">
                          {{ item.strUsuarioPOS.toUpperCase() }}
                        </template>
                        <template v-slot:[`item.intPOSTipoServicio`]="{ item }">
                          {{ getServicio(item.intPOSTipoServicio) }}
                        </template>
                        <template v-slot:[`item.datFecha`]="{ item }">
                          {{ toDateString(item.datFecha, 1) }}
                        </template>
                        <template v-slot:[`body.append`]="{headers}">
                          <tr class="summary">
                              <td v-for="(header,i) in headers" :key="i">
                                <div v-if="header.text == 'Subtotal'">
                                {{isMoney( sumField('dblSubtotal') )}}</div>
                                <div v-if="header.text == 'Total'">
                                {{isMoney( sumField('dblTotal') )}}</div>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <v-row v-show="visibleSalidas">
                    <v-col>
                      <v-data-table :headers="salidaCaja" :items="resultsSalidas">
                        <template v-slot:[`item.dblImporte`]="{ item }">
                          {{ isMoney(item.dblImporte) }}
                        </template>
                        <template v-slot:[`item.intPOSSalidaCaja`]="{ item }">
                          <!-- <v-icon small @click="editItem(item)"> mdi-pencil </v-icon> -->
                          <v-icon small @click="printSalidas(item)"> mdi-delete </v-icon>
                        </template>
                        <template v-slot:[`body.append`]="{headers}">
                          <tr class="summary">
                              <td v-for="(header,i) in headers" :key="i">
                                <div v-if="header.text == 'Importe'">
                                {{isMoney( sumFieldSC('dblImporte') )}}</div>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <v-row v-show="visibleArqueos">
                    <v-col>
                      <v-data-table :headers="arqueos" :items="resultsArqueos">
                        <template v-slot:[`item.dblImporte`]="{ item }">
                          {{ isMoney(item.dblImporte) }}
                        </template>
                        <template v-slot:[`item.intPOSTipoMovimientoTurno`]="{ item }">
                          {{ getMovimiento(item.intPOSTipoMovimientoTurno) }}
                        </template>
                        <template v-slot:[`item.intPOSRegister`]="{ item }">
                          <!-- <v-icon small @click="editItem(item)"> mdi-pencil </v-icon> -->
                          <v-icon small @click="printArqueos(item)"> mdi-printer </v-icon>
                        </template>
                        <template v-slot:[`body.append`]="{headers}">
                          <tr class="summary">
                              <td v-for="(header,i) in headers" :key="i">
                                <div v-if="header.text == 'Importe'">
                                {{isMoney( sumFieldAr('dblImporte') )}}</div>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Loader from "../components/Loader.vue";
import { Session, Toast, ErrorWeb,Dialog } from "../controllers/DataController.js";
import { POSEncFunctions } from "../controllers/POSController";
import { isMoney, toDateString } from "../js/utilities";
import { Data } from "../controllers/DataController.js";
import { POSSalidaCaja } from "../controllers/POSController";
import { POSRegister } from "../controllers/RegisterController.js";
import moment from "moment";
import PrinterDialog from "../components/PrinterDialogComponent.vue";

export default {
  data() {
    return {
      session: new Session(),
      dataFunctions: new Data(),
      ordenFunctions: new POSEncFunctions(),
      salida: new POSSalidaCaja(),
      registro: new POSRegister(),
      printer: new Dialog(0, 0, "", "", ""),
      usuarios: [],
      title: this.$config.ordenTitle,
      impresoras: [],
      versiones: [
        { value: 1, name: "Ventas" },
        { value: 2, name: "Salidas de Caja" },
        { value: 3, name: "Arqueos obligatorios" },
      ],
      version: 1,
      info: {
        usuario: "",
        // fecha_inicio:  moment(new Date()).format("DD/MM/YYYY"),//format("yyyy-MM-DD"),
        // fecha_fin:moment().format("DD/MM/YYYY"),//format("yyyy-MM-DD"),
        fecha_inicio: moment().format("yyyy-MM-DD"),
        fecha_fin: moment().format("yyyy-MM-DD"),
        intPOSRegister:"0"
        
      },
      loading: false,
      headers: [
        { text: "Folio", value: "intFolio" },
        { text: "Fecha", value: "datFecha" },
        { text: 'Factura', value: "intOrden" },
        { text: "Estatus", value: "intEstatus" },
        { text: "Tipo", value: "intPOSTipoServicio" },
        { text: "Metodo de Pago", value: "strNombre" },
        { text: "Importe", value: "importe" },
        { text: "Referencia", value: "strMesa" },
        { text: "Subtotal", value: "dblSubtotal" },
        { text: "Total", value: "dblTotal" },
        { text: "Usuario", value: "strUsuarioPOS" },
      ],
      salidaCaja: [
        // {text: "#", value: "intPOSTipoSalida", align: ' d-none' },
        { text: "Tipo Salida", value: "strTipoSalida" },
        { text: "Salida", value: "strSalida" },
        { text: "Importe", value: "dblImporte" },
        { text: "Comentario", value: "strComentario" },
        { text: "Empleado", value: "strEmpleado" },
        { text: "Acciones", value: "intPOSSalidaCaja" },
      ],
      arqueos: [
        { text: "Folio", value: "intFolio" },
        { text: "Importe", value: "dblImporte" },
        { text: "Captura", value: "strUsuarioPOS" },
        { text: "Comentario", value: "strComentario" },
        { text: "Tipo", value: "intPOSTipoMovimientoTurno" },
        { text: "Acciones", value: "intPOSRegister" },
      ],
      results: [],
      POSInfo: null,
      resultsSalidas: [],
      resultsArqueos: [],
      visibleVenta:true,
      visibleSalidas:false,
      visibleArqueos:false,
      config_21: this.$config.opciones.find((c) => c.id == 21)
    };
  },
  components: {
    Loader,PrinterDialog,
  },
  watch: {
    ["version"](newVal) {
      if (newVal == 1) {
        this.visibleVenta=true,
        this.visibleSalidas=false,
        this.visibleArqueos=false
      } else if (newVal == 2) {
        this.visibleVenta=false,
        this.visibleSalidas=true,
        this.visibleArqueos=false
        
      }else{
        this.visibleVenta=false,
        this.visibleSalidas=false,
        this.visibleArqueos=true
      }
    },
  },
  methods: {
    sumField(key) {
      console.log(key)
        // sum data in give key (property) 
        return this.results.reduce((a, b) => a + (b[key] || 0), 0)
    },
    sumFieldSC(key) {
      console.log(key)
        // sum data in give key (property)
        return this.resultsSalidas.reduce((a, b) => a + (b[key] || 0), 0)
    },
    sumFieldAr(key) {
      console.log(key)
        // sum data in give key (property)
        return this.resultsArqueos.reduce((a, b) => a + (b[key] || 0), 0)
    },
    isMoney: isMoney,
    toDateString: toDateString,
    getEstatus(intEstatus) {
        if (intEstatus == 2) {
        return "PREPARACION";
      } else if (intEstatus == 4) {
        return "PAGADA";
      } else if (intEstatus == 8 || intEstatus == 9) {
        return "CANCELADA";
      } else return intEstatus;
    },
    goBack() {
      this.$router.push("/Main");
    },
    getServicio(servicio) {
      let config_21 = this.$config.opciones.find((c) => c.id == 21);
      if (config_21.valor) {
        if (servicio == 2) return "FACTURA";
      }
      else{
        if (servicio == 1) return "MESA";
        else if (servicio == 2) return "DOMICILIO";
        else if (servicio == 3) return "LLEVAR";
      }
     
    },
    getMovimiento(intPOSTipoMovimientoTurno) {
      if (intPOSTipoMovimientoTurno == 1) return "APERTURA";
      else if (intPOSTipoMovimientoTurno == 2) return "ARQUEO";
      else if (intPOSTipoMovimientoTurno == 3) return "CIERRE";
    },
    async buscar() {
      this.loading = true;
      try {
        
        if( this.version == 2 && this.info.usuario ==  "")
          throw "Seleccione un Usuario";
         
          if(this.version == 1){
            this.results = await this.ordenFunctions.administradorTicket(
              this.session.empresa,
              this.version,
              this.info
            );
          }else if(this.version == 2){
            let intPOSRegister =this.usuarios.find(x => x.strUsuarioAlta== this.info.usuario).intPOSRegister;
            this.info.intPOSRegister =String(intPOSRegister);

              this.resultsSalidas = await this.ordenFunctions.administradorTicket(
              this.session.empresa,
              this.version,
              this.info
           );
          }else if(this.version == 3){
            this.resultsArqueos = await this.ordenFunctions.administradorTicket(
              this.session.empresa,
              this.version,
              this.info
            );
        
          }
          
        
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async printSalidas(item) {
      this.loading = true;
      try {
        this.salida= await this.salida.RetrieveCaja_id( item.intPOSSalidaCaja);
       
        if(item.intPOSTipoSalida==2)
        {
          if (!this.salida.dataDetalleComision)
            this.salida.dataDetalleComision =  await this.salida.RetrieveCaja( item.intPOSSalidaCaja);
        }
          this.salida.strPOSTipoSalida=item.strPOSTipoSalida
          this.salida.strPOSSalida= item.strPOSSalida
          this.salida.strUsuarioSalida=this.session.usuario;
          this.salida.strEmpleado=item.strEmpleado

          this.salida.POSInfo = this.POSInfo;
          this.salida.StrTerminal = this.session.terminal
          this.salida.strUsuarioAlta = this.session.usuario;
          this.salida.datFechaAlta =this.toIsoString(new Date());
          
          if(this.config_21.valor){
          this.printer.event ="salidaCajaDicex";
        }
        else
          this.printer.event ="salidaCaja";

          this.printer.visible = true;
          this.printer.printable =  this.salida;
     } catch (e) {
       this.$toast.error(ErrorWeb(e), new Toast());
     } finally {
        this.loading = false;
      }
    },
    async printArqueos(item) {
      this.loading = true;
      try {
         if(item.intPOSTipoMovimientoTurno==2)
        {
              let POSRegister = await this.ordenFunctions.getArqueos(item.intPOSRegister)
              console.log(POSRegister)
              this.printer.event = "arqueo";
              this.printer.visible = true;
              this.printer.printable = POSRegister;
        }
        if(item.intPOSTipoMovimientoTurno==3)
        {
              let POSRegister = await this.ordenFunctions.getArqueos(item.intPOSRegister)
              this.printer.event = "cierre";
              this.printer.visible = true;
              this.printer.printable = POSRegister;
        }
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      }finally {
          this.loading = false;
        }
    },
    toIsoString(date) {
      var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? "+" : "-",
        pad = function (num) {
          var norm = Math.floor(Math.abs(num));
          return (norm < 10 ? "0" : "") + norm;
        };

      return (
        date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate()) +
        "T" +
        pad(date.getHours()) +
        ":" +
        pad(date.getMinutes()) +
        ":" +
        pad(date.getSeconds()) +
        dif +
        pad(tzo / 60) +
        ":" +
        pad(tzo % 60)
      );
    },
  },
  async mounted() {
    let session = new Session();
    if (!session.usuario) {
      this.$router.push("/");
    } else {
      this.loading = true;
      try {
        this.usuarios = await this.dataFunctions.loadUsuariosOrdenes();
        this.impresoras = await this.dataFunctions.loadImpresoras();
        this.POSInfo = await this.dataFunctions.loadDataPOSInfo();
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style></style>
