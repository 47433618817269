<template>
  <v-card color="white" min-width="100%" hover class="rounded-xl" elevation="50" v-show="mostrarArticulos">
    <Keyboard v-model="keyboard" :layout="layout" />
    <v-card-title>
      <v-row no-gutters>
        <v-col>
          <v-text-field
            placeholder="Buscar producto, articulo o platillo..."
            @keyup.enter="Buscar($event.target.value)"
            v-model="articuloBusqueda"
            @click="showKeyboard($event, 'normal')"
            :append-outer-icon="!buscarArticulo ? null : 'mdi-keyboard-return'"
            @click:append-outer="Limpiar()"
          >
          </v-text-field>
        </v-col>
        <v-col v-if="this.keyboardSession" md="2">
          <br />
          <v-btn block color="secondary" @click="Buscar(articuloBusqueda)"
            >Enter</v-btn
          ></v-col
        >
      </v-row>
    </v-card-title>
    <v-card-text>
      <div v-if="familiaBusqueda.strFamilia != ''">
        <v-row v-if="codigo_barra.activo" style="font-size: small">
          <v-col>
            <v-btn @click="activarBarcode" block color="success">
              <v-icon left> mdi-barcode-scan </v-icon>
              Modo Codigo Barras
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="codigo_barra.activo" style="font-size: small">
          <v-col>
            <v-text-field
              dense
              ref="barcodeInput"
              name="name"
              v-model="barcodeInput"
              label="Barcode"
              outlined
              block
              @click="showKeyboard($event, 'numeric')"
              @keyup.enter="barcodeSearch"
            ></v-text-field>
          </v-col>
          <v-col v-if="this.keyboardSession" md="2">
            <v-btn block color="secondary" @click="barcodeSearch">Enter</v-btn></v-col
          >
        </v-row>
        <v-row v-if="!buscarArticulo && articulo_especial.activo" no-gutters>
          <v-col>
            <v-btn
              rounded
              @click="especial('articulo')"
              :color="articulo_especial.color"
              block
              >{{ articulo_especial.label }}</v-btn
            >
          </v-col>
        </v-row>
        <v-row v-if="!buscarArticulo && bebida_especial.activo" no-gutters>
          <v-col>
            <v-btn
              rounded
              @click="especial('bebida')"
              :color="bebida_especial.color"
              block
              >{{ bebida_especial.label }}</v-btn
            >
          </v-col>
        </v-row>
        <transition name="slide-fade">
          <v-row v-if="!buscarArticulo" style="font-size: small">
            <v-col
              :md="version == 'desktop' ? 4 : 6"
              :xs="version == 'desktop' ? 4 : 6"
              :lg="version == 'desktop' ? 4 : 6"
              :xl="version == 'desktop' ? 4 : 6"
              :sm="version == 'desktop' ? 4 : 6"
              v-for="grupo in grupos"
              :key="grupo.intFamilia"
            >
              <v-card
                shaped
                min-height="100%"
                min-width="100%"
                @click="SetFamilia(grupo)"
                dark
              >
                <v-card-text class="text-center text--white"
                  ><h4>{{ grupo.strFamilia }}</h4></v-card-text
                >
              </v-card>
            </v-col>
          </v-row>
        </transition>
        <transition name="slide-fade">
          <v-card class="overflow-y-auto" max-height="400" v-if="buscarArticulo">
            <v-card-title class="text-center">
              <v-spacer></v-spacer>
              <h3>{{ familiaBusqueda.strFamilia }}</h3>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text>
              <v-container fluid v-if="version == 'desktop'">
                <v-row>
                  <v-col
                    md="3"
                    v-for="articulo in articulosMuestra"
                    :key="articulo.intArticulo"
                  >
                    <v-card
                      shaped
                      min-height="100%"
                      min-width="100%"
                      color="secondary"
                      @click="selectedArticulo(articulo)"
                      light
                    >
                      <v-img
                        v-if="!imageValidation.valor"
                        :src="articulo.strImagen"
                        height="100px"
                      ></v-img>
                      <v-card-title>
                        <h6>
                          {{ articulo.strNombreCorto + " - " + toMoney(articulo.dblPF) }}
                        </h6>
                      </v-card-title>
                      <v-card-subtitle>{{ articulo.strNombre }}</v-card-subtitle>
                      <v-card-actions v-if="!imageValidation.valor">
                        <v-btn @click="resize(articulo.strImagen)" icon>
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn></v-card-actions
                      >
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
              <v-container v-else>
                <v-row v-for="articulo in articulosMuestra" :key="articulo.intArticulo">
                  <v-col>
                    <v-card
                      shaped
                      min-height="100%"
                      min-width="100%"
                      color="secondary"
                      @click="selectedArticulo(articulo)"
                      light
                    >
                      <v-img :src="articulo.strImagen" height="65px"></v-img>
                      <v-card-title>
                        <h6>
                          {{ articulo.strNombreCorto + " - " + toMoney(articulo.dblPF) }}
                        </h6>
                      </v-card-title>
                      <v-card-subtitle>{{ articulo.strNombre }}</v-card-subtitle>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </transition>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
var numeral = require("numeral");
import { Toast } from "../controllers/DataController.js";
import { Session } from "../controllers/DataController.js";
import Keyboard from "./Keyboard.vue";

export default {
  props: {
    version: {
      type: String,
      default: "desktop",
    },
    idOrder: {
      type: Number,
      default: 0,
    },
    idComponent: {
      type: String,
      default: "tipoPago",
    },
    grupos: {
      type: Array,
      default: () => [],
    },
    articulos: {
      type: Array,
      default: () => [],
    },
    articulo_especial: {
      type: Object,
      default: () => {},
    },
    bebida_especial: {
      type: Object,
      default: () => {},
    },
    codigo_barra: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Keyboard,
  },
  data() {
    return {
      tipo: "",
      buscarArticulo: false,
      articuloBusqueda: "",
      familiaBusqueda: {},
      articulosMuestra: [],
      image: [],
      imageValidation: this.$config.opciones.find((c) => c.id == 8),
      config_10: false,
      barcodeMode: false,
      barcodeInput: "",
      barcode: "",
      keyboardSession: false,
      layout: "compact",
      keyboard: {
        visible: false,
        input: null,
      },
      mostrarArticulos:true
    };
  },
  computed: {
    getColBusqueda() {
      if (this.buscarArticulo) {
        return "col-10";
      } else {
        return "col-12";
      }
    },
    images() {
      let images = [];
      this.articulos.forEach((articulo) => {
        images.push(articulo.strImagen2);
      });
      return images;
    },
  },
  mounted() {
    try {
      this.keyboardSession = new Session().keyboard;

      let config = this.$config.opciones.find((c) => c.id == 10);
      if (config.valor) {
        this.config_10 = true;
      } else {
        this.config_10 = false;
      }
      //conf para mostrar panel de articulos
      let config_20 = this.$config.opciones.find((c) => c.id == 20);
      if (config_20.valor) {
        this.mostrarArticulos = false;
      } else {
        this.mostrarArticulos = true;
      }
      
    } catch (e) {
      this.$toast.error("Error en configuracion 10. " + e, new Toast());
    }
  },
  methods: {
    showKeyboard(e, layout) {
      if (this.keyboardSession) {
        this.layout = layout;
        this.keyboard.input = e.target;
        this.keyboard.visible = true;
      }
    },
    toMoney(value) {
      return numeral(value).format("$0,0.00");
    },
    selectedArticulo(articulo) {
      this.$emit("selectedarticulo", articulo);
      if (!this.config_10) this.Limpiar();
    },
    SetFamilia(familia) {
      this.$emit("changed", familia);
      this.buscarArticulo = true;
      this.familiaBusqueda = familia;
      this.filterArticulos(this.articulos, familia.strFamilia);
    },
    Limpiar() {
      this.buscarArticulo = false;
      this.familiaBusqueda = {};
      this.articuloBusqueda = "";
    },
    resize(img) {
      this.image = [img];
      console.log(this.$viewer);
      this.$viewer.show();
    },
    inited(viewer) {
      this.$viewer = viewer;
    },
    Buscar(value) {
      this.articuloBusqueda = value;
      if (value != "") {
        this.buscarArticulo = true;
        this.filterArticulosNombre(this.articulos, value);
      } else {
        this.buscarArticulo = false;
        this.Limpiar();
      }
    },
    filterArticulos(articulos, familia) {
      this.articulosMuestra = articulos.filter(
        (articulo) => articulo.strFamilia == familia
      );
    },
    filterArticulosNombre(articulos, nombre) {
      this.articulosMuestra = articulos.filter((articulo) => {
        const x = articulo.strNombre.toLowerCase();
        if (x.includes(nombre.toLowerCase())) return articulo;
      });
    },
    activarBarcode() {
      this.barcodeMode = true;
      this.$refs.barcodeInput.focus();
    },
    especial(version) {
      this.$emit("especial", version);
      this.Limpiar();
    },
    barcodeSearch() {
      this.barcode = this.barcodeInput;
      this.barcodeInput = "";
      let articulo = this.articulos.find((x) => x.strCodigoBarras == this.barcode);
      if (articulo) {
        this.$emit("barcode", articulo);
      } else {
        this.$toast.error(
          "No se encontro el articulo con codigo " + this.barcode,
          new Toast()
        );
      }
    },
  },
};
</script>

<style></style>
