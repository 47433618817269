<template>
  <v-container fluid>
    <Keyboard v-model="keyboard" layout="compact" />
    <v-row no-gutters>
      <v-col>
        <v-select
          rounded
          :readonly="disabled"
          :items="data"
          outlined
          item-text="strNombre"
          item-value="intPOSTipoVenta"
          label="Tipo Venta"
          v-model="localValue.tipo"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          rounded
          :readonly="disabled"
          v-model="localValue.clave"
          label="Clave"
          outlined
          @click="showKeyboard($event)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          rounded
          v-model="localValue.telefono"
          label="Telefono"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Session } from "../controllers/DataController.js";
import Keyboard from "./Keyboard.vue";
export default {
  props: {
    value: {
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Keyboard,
  },
  data() {
    return {
      localValue: this.value,
      keyboardSession: false,
      keyboard: {
        visible: false,
        input: null,
      },
    };
  },
  methods: {
    showKeyboard(e) {
      if (this.keyboardSession && !this.disabled) {
        this.keyboard.input = e.target;
        this.keyboard.visible = true;
      }
    },
  },
  mounted() {
    this.keyboardSession = new Session().keyboard;
    console.log(this.localValue)
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style></style>
