<template>
  <!-- <modal name="modal-propina">
        <div class="card">
            <div class="card-header">
                Propina
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <label>Porcentaje</label>
                        <input class="form-control" type="number" name="name" v-model="porcentajePropina"
                            @change="calcularPropina()" />
                    </div>
                    <div class="col">
                        <label>Propina</label>
                        <input class="form-control" type="number" name="name" v-model="posenc.dblPropina" />
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col">
                        <vs-button @click="agregarPropinaOrden()" color="danger" gradient block>Guardar</vs-button>
                    </div>
                </div>
            </div>
        </div>
    </modal> -->
  <v-card color="white" min-width="100%" hover class="rounded-xl" elevation="50">
    <Keyboard v-model="keyboard" layout="compact" />

    <v-toolbar color="primary" dark>
      <v-spacer></v-spacer>
      <v-toolbar-title class="subtitle-1" v-if="posenc.intOrden > 0"
        > {{ title + posenc.intOrden }}</v-toolbar-title
      >
      <v-toolbar-title v-else class="subtitle-1">Nueva {{ title }} </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-toolbar elevation="0" dark v-if="version == 'mobile'" color="primary" dense>
      <v-spacer></v-spacer>
      <v-btn @click="opcionesorden()" icon>
        <v-icon>mdi-format-list-bulleted</v-icon>
      </v-btn>
      <v-btn @click="imprimir()" icon>
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-title>
      <v-container fluid>
        <v-row v-if="version == 'desktop'">
          <v-col class="body-2"> {{ detalles.length }} productos </v-col>
          <v-col md="2">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="opcionesorden()" icon>
                  <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </template>
              <span>Opciones</span>
            </v-tooltip>
          </v-col>
          <v-col md="2">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="imprimir()" icon>
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>Imprimir</span>
            </v-tooltip>
          </v-col>
          <v-col md="2">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="propina()" icon>
                  <v-icon>mdi-currency-usd</v-icon>
                </v-btn>
              </template>
              <span>Propina</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <v-expansion-panels focusable>
        <v-expansion-panel v-for="(detalle, index) in detalles" :key="index">
          <v-expansion-panel-header dark>
            <div class="col-8" style="font-size: small">
              ({{ detalle.dblCantidad }}) {{ detalle.strNombre }}
            </div>
            <div class="col-4">
              <div class="row">
                <v-btn @click="opciones(detalle, index)" icon>
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
                <v-btn @click="imprimirDetalle(detalle, index)" icon>
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container>
              <v-row>
                <ul style="font-size: smaller">
                  <li
                    v-for="configuracion in detalle.posDetArticuloConfiguracion"
                    :key="configuracion.intPOSArticuloConfiguracion"
                  >
                  ({{ configuracion.dblCantidad }})  {{
                      configuracion.strNombre + " - " + toMoney(configuracion.dbltotal)
                    }}
                  </li>
                </ul>
              </v-row>
              <v-row>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Precio</th>
                        <th>Descuento</th>
                        <th>IVA</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ detalle.dblCantidad }}</td>
                        <td>{{ toMoney(detalle.dblPU) }}</td>
                        <td>
                          {{ toPercent(detalle.dblPorcentajeDescuento / 100) }}
                        </td>
                        <td>{{ toPercent(detalle.dblPorcentajeIVA / 100) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    dense
                    v-model="detalle.strNota"
                    outlined
                    rounded
                    auto-grow
                    rows="1"
                    label="Nota"
                    block
                    @click="showKeyboard($event)"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-for="(detalle, index) in detallesCancelados"
          :key="index + 100"
        >
          <v-expansion-panel-header dark color="#D52941">
            <div class="col-8" style="font-size: small">
              ({{ detalle.dblCantidad }}) {{ detalle.strNombre }} -
              {{ toMoney(detalle.dblTotal) }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container fluid>
              <v-row>
                <v-col>
                  <p v-if="detalle.intCancelacionDevolucion == 9">
                    Cancelado - {{ detalle.strComentarioCancelacion }}
                  </p>
                  <p v-if="detalle.intCancelacionDevolucion == 8">
                    Devuelto - {{ detalle.strComentarioCancelacion }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="this.posenc.dblEnvio1 > 0">
          <v-expansion-panel-header dark color="#c9c9c9">
            <div class="col-8" style="font-size: small">ENVIO</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container fluid>
              <v-row>
                <v-col> {{ toMoney(this.posenc.dblEnvio1) }} </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      
      </v-expansion-panels>
    </v-card-text>
    <v-card-text>
      <v-textarea
        dense
        v-model="posenc.strNota"
        outlined
        rounded
        auto-grow
        rows="1"
        label="Nota"
        block
        @click="showKeyboard($event)"
      ></v-textarea>
    </v-card-text>

    <v-footer>
      <v-btn rounded v-if="proceso == 1" color="primary" light block @click="preparar()"
        >POR PAGAR {{ toMoney(totalPago) }}
      </v-btn>
      <v-btn rounded v-if="proceso == 2" color="secondary" light block @click="pago()"
        >PAGO {{ toMoney(totalPago) }}</v-btn
      >
      <v-btn rounded v-if="proceso == 3" color="pink darken-1" block @click="agregar()"
        >AGREGAR NUEVOS PRODUCTOS</v-btn
      >
    </v-footer>
  </v-card>
</template>

<script>
var numeral = require("numeral");
var math = require("mathjs");
var moment = require("moment");
import { Session } from "../controllers/DataController.js";
import Keyboard from "./Keyboard.vue";
export default {
  props: {
    configuracion3: {
      type: Object,
      default: () => {},
    },
    proceso: {
      type: Number,
      default: 1,
    },
    version: {
      type: String,
      default: "desktop",
    },
    value: {
      required: true,
    },
  },
  components: {
    Keyboard,
  },
  data() {
    return {
      tiempo: "",
      porcentajePropina: 0,
      totalPago: 0.0,
      totalDescuento: 0.0,
      totalIVA: 0.0,
      posenc: this.value,
      keyboardSession: false,
      keyboard: {
        visible: false,
        input: null,
      },
      title: this.$config.ordenTitle,
      btnTitle: this.$config.btnPreprar
      
    };
  },
  methods: {
    showKeyboard(e) {
      if (this.keyboardSession && !this.disabled) {
        this.keyboard.input = e.target;
        this.keyboard.visible = true;
      }
    },
    toPercent(value) {
      return numeral(value).format("0.00%");
    },
    toMoney(value) {
      return numeral(value).format("$0,0.00");
    },
    opcionesorden() {
      this.$emit("opcionorden", this.posenc);
    },
    opciones(detalle, index) {
      this.$emit("opciondetalle", { index: index, ...detalle });
    },
    imprimirDetalle(detalle, index) {
      this.$emit("imprimirdetalle", { index: index, ...detalle });
    },
    preparar() {
      this.$emit("preparar", this.posenc);
    },
    pago() {
      if (this.configuracion3.valor) {
        this.$emit("prepararpagar", this.posenc);
      } else {
        this.$emit("pagar", this.posenc);
      }
    },
    propina() {
      this.$modal.show("modal-propina");
    }, 
    agregarPropinaOrden() {
      this.$modal.hide("modal-propina");
    },
    imprimir() {
      this.$emit("imprimir", this.posenc);
    },
    agregar() {
      this.posenc.posDet = this.detalles;
      this.$emit("agregar", this.posenc);
    },
    calcularPropina() {
      if (this.porcentajePropina == 0) {
        this.posenc.dblPropina = 0.0;
      }
      if (this.porcentajePropina == "") {
        this.posenc.dblPropina = 0.0;
      }
      if (this.porcentajePropina > 0) {
        let x = math
          .chain(math.round(this.total, 2))
          .multiply(math.round(this.porcentajePropina, 2))
          .divide(100)
          .done();
        this.posenc.dblPropina = math.round(x, 2);
      } else {
        this.posenc.dblPropina = 0.0;
      }
    },
    contabilizarPago() {
      
      if (this.detalles.length > 0) {
        this.totalPago = this.detalles.reduce((a, b) => {
          let iva = math
            .chain(b.dblPU)
            .multiply(b.dblCantidad)
            .multiply(b.dblPorcentajeIVA)
            .divide(100)
            .done();
          let ish = math
            .chain(b.dblPU)
            .multiply(b.dblCantidad)
            .multiply(b.dblPorcentajeISH)
            .divide(100)
            .done();
          let precio = math.chain(b.dblPU).multiply(b.dblCantidad).done();
          let x = math.chain(iva).add(ish).add(precio).done();
          let descuento=0.0;

          if(b.intTipoDescuento == 1){
            descuento = math
            .chain(x)
            .multiply(b.dblPorcentajeDescuento)
            .divide(100)
            .done();
          }else if (b.intTipoDescuento == 2){
            descuento = math
            .chain(b.dblPorcentajeDescuento)
            .done();
          }
          
          // let descuento = math
          //   .chain(x)
          //   .multiply(b.dblPorcentajeDescuento)
          //   .divide(100)
          //   .done();
         
            return math
            .chain(a)
            .add(math.round(precio, 2))
            .add(math.round(iva, 2))
            .add(math.round(ish, 2))
            .subtract(math.round(descuento, 2))
            .done();
         
        }, 0);
        //  let pago=math.chain(this.totalPago).add(this.posenc.dblEnvio1);
        //  let pago=Math.round(this.totalPago,2)
        //  this.totalPago = math.chain(this.totalPago).add(this.posenc.dblEnvio1);
        // this.totalPago =  Math.round(pago,2)
      


        if(this.posenc.dblPorcentajeDescuento > 0)
        {
          this.totalPago = math.chain(this.totalPago).add(this.posenc.dblEnvio1).subtract(math.round(this.posenc.dblImporteDescuento, 2)).done();
        }
        else{
          this.totalPago = math.chain(this.totalPago).add(this.posenc.dblEnvio1).done();
        }
      } else {
        this.totalPago = 0.0;
      }
    },
    openDetalle(indexDet) {
      //const indexDet = this.posenc.posDet.findIndex((obj => obj.intPOSEnc == detalle.intPOSEnc));
      if (this.posenc.posDet[indexDet].open) {
        this.posenc.posDet[indexDet].icon = "fas fa-arrow-up";
        this.posenc.posDet[indexDet].open = false;
      } else {
        this.posenc.posDet[indexDet].icon = "fas fa-arrow-down";
        this.posenc.posDet[indexDet].open = true;
      }
    },
  },
  computed: {
    // total() {
    //   if (this.detalles.length > 0) {
    //     return this.detalles.reduce((a, b) => {
    //       return math.chain(a).add(math.round(b.dblTotal, 2)).done();
    //       // let total= math.chain(a).add(math.round(b.dblTotal, 2)).done()
    //       // return math.round(total,2)
    //     }, 0);
    //   } else {
    //     return 0.0;
    //   }
    // },
    detalles() {
      return this.posenc.posDet.filter(
        (detalle) => detalle.intEstatus != 8 && detalle.intEstatus != 9
      );
    },
    detallesCancelados() {
      return this.posenc.posDet.filter(
        (detalle) => detalle.intEstatus == 8 || detalle.intEstatus == 9
      );
    },
  },
  mounted() {
    setInterval(() => (this.tiempo = moment().format("LTS")), 1000);
    this.contabilizarPago();
    this.keyboardSession = new Session().keyboard;
  },
  watch: {
    porcentajePropina: {
      handler() {
        this.calcularPropina();
      },
      deep: true,
    },
    posenc: {
      handler(newValue) {
        this.contabilizarPago();
        this.$emit("input", newValue);
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.posenc = newValue;
      },
      deep: true,
    },
  },
};
</script>

<style></style>
