<template>
  <v-dialog v-model="localValue" width="500px">
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Opciones de {{title}}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-list flat>
          <v-list-item @click="agregarProducto()" v-show="visible">
            <v-list-item-icon>
              <v-icon>mdi-cart-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Agregar Producto</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="eliminarOrden()"  v-show="visible">
            <v-list-item-icon>
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Eliminar</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="cancelar()">
            <v-list-item-icon>
              <v-icon>mdi-cancel</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Cancelar</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item @click="separarCuentas()">
            <v-list-item-icon>
              <v-icon>mdi-arrow-expand</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Separar Cuentas</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-list-item @click="aplicarDescuento()" >
            <v-list-item-icon>
              <v-icon>mdi-cash</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Descuento</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="cortesia()" v-show="visible">
            <v-list-item-icon>
              <v-icon>mdi-gift</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Cortesia</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="prepago()"  v-show="visible">
            <v-list-item-icon>
              <v-icon>mdi-printer</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Ticket Prepago</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { Toast } from "../controllers/DataController.js";
export default {
  props: {
    posenc: {
      type: Object,
      default: () => {},
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
      visible:true,
      title: this.$config.ordenTitle
    };
  },
  watch: {
    porcentajePropina: {
      handler() {
        this.calcularPropina();
      },
      deep: true,
    },
    localValue: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.localValue = newValue;
      },
      deep: true,
    },
  },
  mounted() {
    try {
      //conf para mostrar panel de articulos
      let config_21 = this.$config.opciones.find((c) => c.id == 21);
      if (config_21.valor) {
        this.visible = false;
      } else {
        this.visible = true;
      }
      
    } catch (e) {
      this.$toast.error("Error en configuracion 21. " + e, new Toast());
    }
  },
  methods: {
    async eliminarOrden() {
      try {
        if (this.posenc.intPOSEnc != 0) {
          throw "No se puede eliminar la orden, la orden ya ha sido creada";
        }
        this.$emit("eliminar", this.posenc);
      } catch (e) {
        this.$toast.error(e, new Toast());
      }
    },
    async cancelar() {
      try {
        if (this.posenc.intPOSEnc == 0) {
          throw "No se puede cancelar la orden, debe de estar en estatus EN PREPARACION o PAGADA";
        }
        this.$emit("cancelar", this.posenc);
      } catch (e) {
        this.$toast.error(e, new Toast());
      }
    },
    cortesia() {
      this.$emit("cortesia");
    },
    agregarProducto() {
      this.$emit("agregar", this.posenc);
    },
    aplicarDescuento() {
      try {
        if (this.posenc.intPOSEnc == 0) {
          throw "No se puede aplicar descuento, primero guarde la orden";
        }
        this.$emit("descuento", this.posenc);
      } catch (e) {
        this.$toast.error(e, new Toast());
      }
    },
    async prepago() {
      try {
        if (this.posenc.intPOSEnc == 0) {
          throw "No se puede imprimir el ticket de prepago, debe de estar en estatus EN PREPARACION";
        }
        this.$emit("prepago", this.posenc); 
      } catch (e) {
        this.$toast.error(e, new Toast());
      }
    },
    async separarCuentas() {
      try {
        if (this.posenc.intPOSEnc == 0) {
          throw "No se puede separar la orden, debe de estar en estatus EN PREPARACION";
        }
        this.$emit("separar", this.posenc);
      } catch (e) {
        this.$toast.error(e, new Toast());
      }
    },
    async juntarCuentas() {
      try {
        if (this.posenc.intPOSEnc == 0) {
          throw "No se puede juntar la orden, debe de estar en estatus EN PREPARACION";
        }
        this.$emit("juntar", this.posenc);
      } catch (e) {
        this.$toast.error(e, new Toast());
      }
    },
  },
};
</script>

<style></style>
