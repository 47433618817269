<template>
  <div data-app>
    <v-dialog
      v-model="localValue.visible"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark>
          <v-btn icon dark persistent @click="cerrar()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-if="localValue.configuration">{{
            localValue.configuration[0].strNombre
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <ArticuloStepper
          @finish="finished"
          v-model="localValue.configuration"
          :steps="steps"
        ></ArticuloStepper>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ArticuloStepper from "./ArticuloStepper.vue";

export default {
  props: {
    value: {
      required: true,
    },
    steps: {
      type: Number,
      default: 0,
    },
    
  },
  components: {
    ArticuloStepper,
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  methods: {
    finished(val) {
      this.$emit("finish", val);
      this.localValue.visible = false;
    },
    cerrar() {
      this.localValue.visible = false;
    },
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style></style>
