<template>
  <div>
    <Loader v-model="loading" />
    <PrinterDialog v-model="printer" :impresoras="impresoras" />
    <v-app-bar app>
      <v-app-bar app color="primary" dark>
        <v-icon @click="goBack">mdi-keyboard-backspace</v-icon>
        <v-spacer></v-spacer>
        <v-toolbar-title>Reimpresion</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon>mdi-account-circle</v-icon> {{ session.usuario.toUpperCase() }}
      </v-app-bar>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title> Informacion </v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        type="date"
                        label="Fecha"
                        outlined
                        v-model="info.fecha"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-select
                        v-model="version"
                        :items="versiones"
                        label="Tipo"
                        item-text="name"
                        item-value="value"
                        outlined
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        @keyup="isNumber($event)"
                        label="Orden"
                        type="number"
                        outlined
                        v-model="info.orden"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-btn x-large block color="primary" @click="buscar()">
                        Imprimir
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import PrinterDialog from "../components/PrinterDialogComponent.vue";

import Loader from "../components/Loader.vue";
import { Session, Toast, ErrorWeb, Dialog } from "../controllers/DataController.js";
import { POSEncFunctions } from "../controllers/POSController";
import { Data } from "../controllers/DataController.js";
import { isNumber } from "../js/utilities";
import moment from "moment";
import { POSUserFunctions } from "../controllers/UserController";

export default {
  data() {
    return {
      userFunctions: new POSUserFunctions(),
      session: new Session(),
      printer: new Dialog(0, 0, "", "", ""),
      dataFunctions: new Data(),
      ordenFunctions: new POSEncFunctions(),
      usuarios: [],
      versiones: [
        { value: 1, name: "Preparacion" },
        { value: 2, name: "Pago" },
      ],
      version: 2,
      info: {
        fecha: moment().format("yyyy-MM-DD"),
        orden: null,
      },
      loading: false,
      orden: null,
      terminal: null,
      POSInfo: null,
      impresoras: [],
    };
  },
  components: {
    Loader,
    PrinterDialog,
  },
  methods: {
    isNumber: isNumber,
    toIsoString(date) {
      var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? "+" : "-",
        pad = function (num) {
          var norm = Math.floor(Math.abs(num));
          return (norm < 10 ? "0" : "") + norm;
        };

      return (
        date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate()) +
        "T" +
        pad(date.getHours()) +
        ":" +
        pad(date.getMinutes()) +
        ":" +
        pad(date.getSeconds()) +
        dif +
        pad(tzo / 60) +
        ":" +
        pad(tzo % 60)
      );
    },
    async reprint(posenc) {
      try {
       
        if (posenc.intPOSEnc == 0) throw "No se puede imprimir la orden";

        posenc.POSInfo = this.POSInfo;
        posenc.StrTerminal = this.terminal.strNombre;
        if (!posenc.posEncPago && this.version == 2)
          posenc.posEncPago = await this.ordenFunctions.pagos(posenc.intPOSEnc);

        if (!posenc.posDet)
          posenc.posDet = await this.ordenFunctions.detalles(posenc.intPOSEnc);

        if (posenc.intHotReservacion) {
          if (posenc.intHotReservacion > 0) {
            let reservacion = await this.ordenFunctions.get_reservacion(
              posenc.intHotReservacion
            );
            posenc.strHabitacion = reservacion.strHabitacion;
            posenc.strApellidoReferencia = reservacion.strApellidoReferencia;
          }
        }

        posenc.bolReimpresion = true;

        if (this.version == 1) this.printer.event = "cocina";
        else if (this.version == 2) {
          let config_21 = this.$config.opciones.find((c) => c.id == 21);
          if(config_21){
          posenc.strRFC=posenc.cliente.strRFC;
          posenc.strNombre= posenc.cliente.strNombre;
          console.log( posenc.strRFC,posenc.strNombre)
            this.printer.event = "pagoDicex";
          }
          else
            this.printer.event = "pago";
        }
        
        this.printer.visible = true;
        posenc.datFechaImpresion = this.toIsoString(new Date());
        this.printer.printable = posenc;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      }
    },
    goBack() {
      this.$router.push("/Main");
    },
    async buscar() {
      this.loading = true;
      try {
        if (this.info.orden == null) {
          throw "Favor de escribir un numero de orden";
        }
        let orden = await this.ordenFunctions.getOrdenReimpresion(
          this.info.fecha,
          this.version,
          this.info.orden
        );
        //* Reimpresion
        this.reprint(orden);
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    let session = new Session();
    if (!session.usuario) {
      this.$router.push("/");
    }
    this.loading = true;
    try {
      this.impresoras = await this.dataFunctions.loadImpresoras();
      this.terminal = await this.userFunctions.terminal(this.session.terminal);
      this.POSInfo = await this.dataFunctions.loadDataPOSInfo();
    } catch (e) {
      this.$toast.error(ErrorWeb(e), new Toast());
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style></style>
