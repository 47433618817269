<template>
  <div>
    <Loader v-model="loading" />
    <PrinterDialog v-model="printer" :impresoras="impresoras" />
    <v-dialog v-model="confirm" persistent max-width="290">
      <v-card>
        <v-card-title> Confirmacion </v-card-title>
        <v-card-text>Desea eliminar el registro?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" text @click="confirm = false"> Cancelar </v-btn>
          <v-btn color="primary" text @click="itemDelete()"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="edit" persistent max-width="290">
      <v-card>
        <v-card-title> Editar </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col>
                <v-text-field
                  label="Importe"
                  min="0"
                  type="number"
                  outlined
                  @keypress="isFloat($event)"
                  prefix="$"
                  v-model="editedItem.dblImporte"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  :readonly="editedItem.intPOSTipoSalida == 1"
                  v-model="editedItem.strComentario"
                  label="Comentario"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" text @click="edit = false"> Cancelar </v-btn>
          <v-btn color="primary" text @click="editConfirm()"> Aceptar </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-app-bar app>
      <v-app-bar app color="primary" dark>
        <v-icon @click="goBack">mdi-keyboard-backspace</v-icon>
        <v-spacer></v-spacer>
        <v-toolbar-title>Salida Caja</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon>mdi-account-circle</v-icon> {{ session.usuario.toUpperCase() }}
      </v-app-bar>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title> Informacion </v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="salida.intPOSTipoSalida"
                        :items="tipos"
                        label="Tipo"
                        item-text="strNombre"
                        item-value="intPOSTipoSalida"
                        outlined
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        v-model="salida.intPOSSalida"
                        :items="tiposSalida"
                        label="Salida"
                        item-text="strNombre"
                        item-value="intPOSSalida"
                        outlined
                      >
                      </v-select>
                    </v-col>
                    <v-col v-show="empleadoIsVisible">
                      <v-select
                        v-model="salida.intEmpleado"
                        :items="empleados"
                        :label="empleadoLabel"
                        item-text="strNombreCompleto"
                        item-value="intEmpleado"
                        outlined
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="Total Envios"
                        outlined
                        :disabled="true"
                        v-model="intCantEnvio"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Importe"
                        min="0"
                        type="number"
                        outlined
                        :disabled="importeDisabled"
                        @keypress="isFloat($event)"
                        prefix="$"
                        v-model="salida.dblImporte"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="salida.strComentario"
                        label="Comentario"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn x-large block color="secondary" @click="registrar()">
                        Registrar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>Resultados</v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-data-table :headers="headers" :items="results">
                        <template v-slot:[`item.dblImporte`]="{ item }">
                          {{ isMoney(item.dblImporte) }}
                        </template>
                        <!-- <template v-slot:[`item.intPOSSalidaCaja`]="{ item }">
                          <v-icon small @click="editItem(item)"> mdi-pencil </v-icon> 
                          <v-icon small @click="confirmDelete(item)"> mdi-delete </v-icon>
                        </template> -->
                        <template v-slot:[`body.append`]="{headers}">
                          <tr class="summary">
                              <td v-for="(header,i) in headers" :key="i">
                                <div v-if="header.text == 'Importe'">
                                {{isMoney( sumField('dblImporte') )}}</div>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Loader from "../components/Loader.vue";
import { Session, Toast, ErrorWeb,Dialog } from "../controllers/DataController.js";
import { POSSalidaCaja,POSTipoSalida } from "../controllers/POSController";
import { isMoney, isFloat, toDateString } from "../js/utilities";
import PrinterDialog from "../components/PrinterDialogComponent.vue";
import { Data } from "../controllers/DataController.js";

export default {
  data() {
    return {
      session: new Session(),
      tipoSalida: new POSTipoSalida(),
      salida: new POSSalidaCaja(),
      editedItem: new POSSalidaCaja(),
      printer: new Dialog(0, 0, "", "", ""),
      dataFunctions: new Data(),
      tipos: [
        // {
        //   intPOSTipoSalida: 1,
        //   strNombre: "COMPRA",
        // },
        // {
        //   intPOSTipoSalida: 2,
        //   strNombre: "COMISIONES",
        // },
      ],
      confirm: false,
      deleteItem: null,
      edit: false,
      empleados: [],
      empleadoText: "strNombre",
      empleadoLabel: "Empleado",
      empleadoIsVisible: false,
      importeDisabled: false,
      tiposSalida: [],
      tiposComision: [],
      tiposCompra: [],
      cajeros: [],
      repartidores: [],
      loading: false,
      headers: [
        { text: "Tipo", value: "strTipoSalida" },
        { text: "Salida", value: "strSalida" },
        { text: "Empleado", value: "strEmpleado" },
        { text: "Comentario", value: "strComentario" },
        { text: "Importe", value: "dblImporte" },
        { text: "Acciones", value: "intPOSSalidaCaja" },
      ],
      results: [],
      intComisionRepa:0,
      intCantEnvio:0,
      POSInfo: null,
      impresoras: [],
      strPOSTipoSalida:"",
      strPOSSalida:"",
      strEmpleado:"",
      config_21: this.$config.opciones.find((c) => c.id == 21)
    };
  },
  components: {
    Loader,
    PrinterDialog,
  },
  watch: {
  async ["salida.intPOSTipoSalida"](newVal) {
    console.log(newVal)
    this.salida.intPOSSalida = null;
      this.empleadoIsVisible = false;
      this.salida.intEmpleado = null;
    if(newVal != null){
      let result = this.tipos.find((x) => x.intPOSTipoSalida == newVal);
      console.log(result)
      console.log(result.strNombre)
      if (result.strNombre == "COMPRAS") {
        this.strPOSTipoSalida="COMPRAS"
        this.tiposSalida = await this.salida.SalidasXTipo(newVal);
      }else if (result.strNombre == "COMISIONES") {
        this.strPOSTipoSalida="COMISIONES"
        this.tiposSalida = await this.salida.SalidasXTipo(newVal);
      }
      else{
        this.tiposSalida = await this.salida.SalidasXTipo(newVal);
      }
    }
    },
    ["salida.intPOSSalida"](newVal) {
      if (newVal) {
        this.salida.intEmpleado = null;
        let result = this.tiposSalida.find((x) => x.intPOSSalida == newVal);
        this.strPOSSalida=result.strNombre;
        if (result.strNombre == "CAJERO") {
          this.empleadoLabel = "Cajero";
          this.empleados = this.cajeros;
          // this.empleadoText = "strNombreCompleto";
          this.empleadoIsVisible = true;
        } else if (result.strNombre == "REPARTIDOR") {
          this.empleadoLabel = "Repartidor";
          this.empleados = this.repartidores;
          // this.empleadoText = "strNombreCompleto";
          
          this.empleadoIsVisible = true;
        } else {
          this.empleadoIsVisible = false;
        }
      }
    },
    async ["salida.intEmpleado"](newVal) {
      this.loading = true;
      try {
        if (newVal != null) {
          if (newVal > 0) {
            let repa = this.empleados.find((x) => x.intEmpleado == newVal);
            this.strEmpleado=repa.strNombreCompleto
            if( this.strEmpleado != this.session.usuario.toUpperCase() && this.strPOSSalida=="CAJERO")
              throw "Favor de seleccionar su usuario";
            
            repa.strNombreCompleto == "REPARTIDOR EXTERNO"  ? this.intComisionRepa=45 : (repa.strNombreCompleto == "REPARTIDOR GRUPO"  ? this.intComisionRepa=40 : this.intComisionRepa=10)
            let response = await this.salida.Comision(newVal,this.intComisionRepa);
            this.salida.dblImporte = parseFloat(response.dblImporte);
            this.intCantEnvio = response.intCantEnvio;
            this.importeDisabled = true;
          } else {
            this.salida.dblImporte=0;
            this.intCantEnvio = 0;
            this.importeDisabled = false;
            throw "El usuario no tiene asignado un empleado";
          }
        } else {
          this.salida.dblImporte=0;
          this.intCantEnvio = 0;
          this.importeDisabled = false;
        }
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
  },
  methods: {
    isFloat: isFloat,
    isMoney: isMoney,
    toDateString: toDateString,
    goBack() {
      this.$router.push("/Main");
    },
    sumField(key) {
        // sum data in give key (property)
        return this.results.reduce((a, b) => a + (b[key] || 0), 0)
    },
    confirmDelete(item) {
      this.deleteItem = item;
      this.confirm = true;
    },
    editItem(item) {
      this.editedItem.intPOSSalidaCaja = item.intPOSSalidaCaja;
      this.editedItem.intPOSSalida = item.intPOSSalida;
      this.editedItem.intPOSTipoSalida = item.intPOSTipoSalida;
      this.editedItem.dblImporte = item.dblImporte;
      this.editedItem.strComentario = item.strComentario;
      this.edit = true;
    },
    async itemDelete() {
      this.loading = true;
      try {
        if (this.deleteItem.intPOSSalidaCaja == 0)
          throw "No se puede eliminar este registro";
        await this.salida.Eliminar(this.deleteItem.intPOSSalidaCaja);
        this.deleteItem = null;
        this.$toast.success("Registro eliminado con exito", new Toast());
        this.results = await this.salida.Data();
        this.confirm = false;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async editConfirm() {
      this.loading = true;
      try {
        if (this.editedItem.dblImporte == 0) throw "No puede tener el importe en $0.00";
        this.editedItem.intEmpresa = this.session.empresa;
        this.editedItem.strUsuarioMod = this.session.usuario;
        this.editedItem.intPOSRegister = this.session.register;
        await this.editedItem.Actualizar();
        this.editedItem = new POSSalidaCaja();
        this.$toast.success("Registro actualizado con exito", new Toast());
        this.results = await this.editedItem.Data();
        this.edit = false;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async registrar() {
      this.loading = true;
      try {
        if (this.salida.dblImporte == 0) throw "No puede tener el importe en $0.00";
        
        this.salida.intEmpresa = this.session.empresa;
        this.salida.intSucursal = this.session.sucursal;
        this.salida.strUsuarioAlta = this.session.usuario;
        this.salida.intPOSRegister = this.session.register;
        this.salida.intComisionRepa =  this.intComisionRepa;
        
        let  salidacaja=await this.salida.Registrar( this.intComisionRepa);
        console.log(salidacaja)
        if(salidacaja.result == 0)
         return  "Error. Existe un registro con el mismo Importe,Salida y comentario,";

          this.salida.dataDetalleComision=await this.salida.RetrieveCaja( salidacaja.result);

        console.log(this.salida.dataDetalleComision)

        this.salida.strPOSTipoSalida=this.strPOSTipoSalida
        this.salida.strPOSSalida= this.strPOSSalida
        this.salida.strUsuarioSalida=this.session.usuario;
        this.salida.strEmpleado=this.strEmpleado
        this.salida.intEnvios=this.intCantEnvio


        this.salida.POSInfo = this.POSInfo;
        this.salida.StrTerminal = this.session.terminal
        this.salida.strUsuarioAlta = this.session.usuario;
        this.salida.datFechaAlta =this.toIsoString(new Date());
        if(this.config_21.valor){
          this.printer.event ="salidaCajaDicex";
        }
        else
        this.printer.event ="salidaCaja";
        this.printer.visible = true;
        this.printer.printable =  this.salida;


        this.salida = new POSSalidaCaja();
        this.$toast.success("Registro guardado con exito", new Toast());
        this.results = await this.salida.Data();
        
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },

   
    
    toIsoString(date) {
      var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? "+" : "-",
        pad = function (num) {
          var norm = Math.floor(Math.abs(num));
          return (norm < 10 ? "0" : "") + norm;
        };

      return (
        date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate()) +
        "T" +
        pad(date.getHours()) +
        ":" +
        pad(date.getMinutes()) +
        ":" +
        pad(date.getSeconds()) +
        dif +
        pad(tzo / 60) +
        ":" +
        pad(tzo % 60)
      );
    },
  },
  async mounted() {
    let session = new Session();
    if (!session.usuario) {
      this.$router.push("/");
    } else {
      this.loading = true;

      try {
        // this.tiposComision = await this.salida.SalidasXTipo(this.salida.intPOSTipoSalida);
        // this.tiposCompra = await this.salida.SalidasXTipo(this.salida.intPOSTipoSalida);
        this.cajeros = await this.salida.Cajeros();
        this.repartidores = await this.salida.Repartidores();
        this.results = await this.salida.Data();
        this.POSInfo = await this.dataFunctions.loadDataPOSInfo();
        this.impresoras = await this.dataFunctions.loadImpresoras();
        this.tipos = await this.tipoSalida.Data();
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style></style>
